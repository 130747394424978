// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { login_layout } from "../store/actions/LoginLayout";
// import { Key, Mail } from "react-feather";
// import Particles from "react-particles-js";
// import Spinner from "../global/Spinner";
// import { toast } from "react-toastify";
// import Axios from "axios";
// import { Home } from "../global/Home";
// import Toaster from "../global/Toaster";
// import img from "../assets/svg/haba_color.svg";
// import img2 from "../assets/img/LOGOW.png";
// import { BounceUp, FadeIn } from "animate-components";
// import { Link } from "react-router-dom";
// import ForgottenPass from "../global/ForgottenPass";
// import { passwordStrength } from "check-password-strength";

// const defaultOptions = [
//   {
//     id: 0,
//     value: "Too weak",
//     minDiversity: 0,
//     minLength: 0,
//   },
//   {
//     id: 1,
//     value: "Weak",
//     minDiversity: 0,
//     minLength: 1,
//   },
//   {
//     id: 2,
//     value: "Medium",
//     minDiversity: 1,
//     minLength: 3,
//   },
//   {
//     id: 3,
//     value: "Strong",
//     minDiversity: 3,
//     minLength: 4,
//   },
// ];
// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       switch: "",
//       email: "",
//       password: "",
//       loading: false,
//       strenght: "",
//       first_name:"",
//       last_name:"",
//       phone_number:"",
//       type:true
//     };
//   }

//   ErrorHandler = (message) => {
//     //console.clear();
//     console.log(message);
//     this.setState({ loading: false });
//     toast.error(message, {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: false,
//     });
//   };

//   handleChange = (event) => {
//     if (event.target.type !== "files") {
//       this.setState({ [event.target.name]: event.target.value });
//       if (event.target.name === "password") {
//         this.setState({
//           strenght: passwordStrength(this.state.password, defaultOptions).value,
//         });
//         if (
//           passwordStrength(this.state.password, defaultOptions).value ==
//           "Too weak"
//         ) {
//           this.setState({ css: "text-danger" });
//         } else if (
//           passwordStrength(this.state.password, defaultOptions).value == "Weak"
//         ) {
//           this.setState({ css: "text-danger" });
//         } else if (
//           passwordStrength(this.state.password, defaultOptions).value ==
//           "Medium"
//         ) {
//           this.setState({ css: "text-warning" });
//         } else if (
//           passwordStrength(this.state.password, defaultOptions).value ==
//           "Strong"
//         ) {
//           this.setState({ css: "text-success" });
//         }
//       }
//     }
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     this.setState({ loading: true });
//     Axios.post(`${Home}enter-ps/${this.state.type?"login":"register"}`, {
//       type: 2,
//       email: this.state.email,
//       password: this.state.password,
//       first_name:this.state.first_name,
//       last_name:this.state.last_name,
//       phone_number:this.state.phone_number,
//       account_type:"enterprise",
//       otp: "none",
//     })
//       .then((res) => {
//         console.log(res);
//         this.setState({ loading: false });
//         let {data} = res
//         if (res.data.success) {
//           localStorage.setItem("userToken", JSON.stringify(data.payload.token));
//           this.props.history.push("/dashboard");
//         } else {
//           this.ErrorHandler(data.message);
//         }
//       })
//       .catch(err=>{
//         console.log(err)
//         if (err.response) {
//           const { data } = err.response
//           this.ErrorHandler(data.message);
//         }else{
//           this.ErrorHandler(err.message);
//         }
//       });
//   };

//   componentDidMount() {
//     this.props.dispatch(login_layout("STEPHEN_WHOCODED"));
//   }

//   componentWillUnmount() {
//     this.props.dispatch(login_layout(""));
//   }
//   Switch = (name) => {
//     this.setState({ switch: name });
//   };
//   render() {
//     return (
//       <>
//         {this.state.switch === "WHOCODED" ? (
//           <ForgottenPass close={() => this.Switch("")} />
//         ) : (
//           ""
//         )}
//         <div className="">
//           <Toaster />

//           <div className="">
//             <div className="content-l d-flex st-h-100 justify-content-center align-items-center bgg">
//               <form
//                 onSubmit={this.handleSubmit}
//                 className="login-form"
//               >
//                 <div className="card mb-0">
//                   {this.state.type?
//                   <div className="card-body">

//                   <div className="text-center mb-3">
//                     <div className="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
//                       <img className="img-fluid h-48px" src={img} />
//                     </div>
//                     <h5 className="text-primary2 mg-b-5 text-center">
//                     Login to your account
//                   </h5>
//                   <p className="text-primary22 mg-b-40 text-muted">
//                   Enter your credentials below
//                   </p>
//                   </div>

//                   <div className="form-group">
//                     <label className="text-primary22 st-label">
//                       Email address
//                     </label>
//                     <input
//                       onChange={this.handleChange}
//                       name="email"
//                       value={this.state.email}
//                       type="email"
//                       required
//                       className="form-control st-login-f"
//                       id="inlineFormInputGroup"
//                       placeholder=""
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label className="text-primary22 st-label">Password</label>
//                     <input
//                       name="password"
//                       value={this.state.password}
//                       onChange={this.handleChange}
//                       type="password"
//                       required
//                       className="form-control st-login-f"
//                       id="inlineFormInputGroup"
//                       placeholder=""
//                     />
//                     <small
//                       style={{ fontSize: "14px" }}
//                       className={`${this.state.css}`}
//                     >
//                       {this.state.strenght}
//                     </small>
//                   </div>
//                   <div
//                     className="text-primary22 tx-13 mg-t0 mb-3 tx-center text-right"
//                     style={{ fontWeight: "900" }}
//                   >
//                                       <Link onClick={()=>this.Switch("WHOCODED")} className="text-primary3" to="#">Forgotten password?</Link>

//                   </div>
//                   <div className="form-group">
//                     {this.state.loading ? (
//                       <Spinner size={25} />
//                     ) : this.state.password.length > 0 &&
//                       this.state.email.length > 0 ? (
//                       <button
//                         type="submit"
//                         className="btn btn-primary st-btn shadow btn-block btn-sm"
//                       >
//                         {" "}
//                         Login
//                       </button>
//                     ) : (
//                       <button
//                         type="button"
//                         disabled={false}
//                         className="btn btn-primary st-btn btn-sm shadow btn-block"
//                       >
//                         {" "}
//                         Login
//                       </button>
//                     )}
//                   </div>

//                     </div>
//                 :

//                 <div className="card-body">

//                 <div className="text-center mb-3">
//                   <div className="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
//                     <img className="img-fluid h-48px" src={img} />
//                   </div>
//                   <h5 className="text-primary2 mg-b-5 text-center">
//                   Create an account
//                 </h5>
//                 <p className="text-primary22 mg-b-40 text-muted">
//                 Enter your details below to register
//                 </p>
//                 </div>

//                   <div className="row">
//                     <div className="col-md-6">
//                       <div className="form-group">
//                       <label className="text-primary22 st-label">
//                         First name
//                       </label>
//                       <input
//                         onChange={this.handleChange}
//                         name="first_name"
//                         value={this.state.first_name}
//                         type="text"
//                         required
//                         className="form-control st-login-f"
//                         id="inlineFormInputGroup"
//                         placeholder=""
//                       />
//                     </div>
//                     </div>

//                     <div className="col-md-6">
//                   <div className="form-group">
//                     <label className="text-primary22 st-label">
//                       Last name
//                     </label>
//                     <input
//                       onChange={this.handleChange}
//                       name="last_name"
//                       value={this.state.last_name}
//                       type="text"
//                       required
//                       className="form-control st-login-f"
//                       id="inlineFormInputGroup"
//                       placeholder=""
//                     />
//                   </div>
//                 </div>

//                   </div>

//                 <div className="form-group">
//                   <label className="text-primary22 st-label">
//                     Phone Number
//                   </label>
//                   <input
//                     onChange={this.handleChange}
//                     name="phone_number"
//                     value={this.state.phone_number}
//                     type="number"
//                     required
//                     className="form-control st-login-f"
//                     id="inlineFormInputGroup"
//                     placeholder="+2347060622780"
//                   />
//                 </div>

//                 <div className="form-group">
//                   <label className="text-primary22 st-label">
//                     Email address
//                   </label>
//                   <input
//                     onChange={this.handleChange}
//                     name="email"
//                     value={this.state.email}
//                     type="email"
//                     required
//                     className="form-control st-login-f"
//                     id="inlineFormInputGroup"
//                     placeholder=""
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label className="text-primary22 st-label">Password</label>
//                   <input
//                     name="password"
//                     value={this.state.password}
//                     onChange={this.handleChange}
//                     type="password"
//                     required
//                     className="form-control st-login-f"
//                     id="inlineFormInputGroup"
//                     placeholder=""
//                   />
//                   <small
//                     style={{ fontSize: "14px" }}
//                     className={`${this.state.css}`}
//                   >
//                     {this.state.strenght}
//                   </small>
//                 </div>

//                 <div className="form-group">
//                   <label className="text-primary22 st-label">Confirm Password</label>
//                   <input
//                     name="password2"
//                     value={this.state.password2}
//                     onChange={this.handleChange}
//                     type="password"
//                     required
//                     className="form-control st-login-f"
//                     id="inlineFormInputGroup"
//                     placeholder=""
//                   />
//                 </div>
//                 {/* <div
//                   className="text-primary22 tx-13 mg-t0 mb-3 tx-center text-right"
//                   style={{ fontWeight: "900" }}
//                 >
//                   <Link onClick={()=>this.setState({type:!this.state.type})} className="text-primary3" to="#">Login to your account</Link>
//                 </div> */}
//                 <div className="form-group">
//                   {this.state.loading ? (
//                     <Spinner size={25} />
//                   ) : this.state.password.length > 0 &&
//                     this.state.email.length > 0 ? (
//                     <button
//                       type="submit"
//                       className="btn btn-primary st-btn shadow btn-block btn-sm"
//                     >
//                       {" "}
//                       Create account
//                     </button>
//                   ) : (
//                     <button
//                       type="button"
//                       disabled={false}
//                       className="btn btn-primary st-btn btn-sm shadow btn-block"
//                     >
//                       {" "}
//                       Create account
//                     </button>
//                   )}
//                 </div>

//                   </div>
//                 }
//                 </div>

//                 {this.state.type?
//                 <div className=" tx-13 mg-t-20 tx-center" style={{fontWeight:'900',color:"#d1cccc"}}>Don't have an account? <Link className="text-light" onClick={()=>this.setState({type:!this.state.type})} to="#">Create an account</Link></div>
//               :
//               <div className=" tx-13 mg-t-20 tx-center" style={{fontWeight:'900',color:"#d1cccc"}}>Alreadt have an account? <Link className="text-light" onClick={()=>this.setState({type:!this.state.type})} to="#">Login to your account</Link></div>
//               }

//               </form>
//             </div>

//           </div>
//         </div>
//       </>
//     );
//   }
// }

// const mapStoreToProps = (store) => {
//   return {
//     data: store,
//   };
// };

// export default connect(mapStoreToProps)(Login);

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { login_layout } from '../store/actions/LoginLayout';
import { Key, Mail } from 'react-feather';
import Particles from 'react-particles-js';
import Spinner from '../global/Spinner';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { Home } from '../global/Home';
import Toaster from '../global/Toaster';
import img from '../assets/svg/haba_color.svg';
import img2 from '../assets/img/LOGOW.png';
import { BounceUp, FadeIn } from 'animate-components';
import { Link } from 'react-router-dom';
import ForgottenPass from '../global/ForgottenPass';
import { passwordStrength } from 'check-password-strength';
import { useHistory } from 'react-router-dom';

const defaultOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 1,
    minLength: 3,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 3,
    minLength: 4,
  },
];

const Login = () => {
  const dispatch = useDispatch();

  const [switchName, setSwitchName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [type, setType] = useState(true);

  const [css, setCss] = useState('');

  const history = useHistory();

  const ErrorHandler = (message) => {
    console.log(message);
    setLoading(false);
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const handleChange = (event) => {
    if (event.target.type !== 'files') {
      if (event.target.name === 'password') {
        setStrength(passwordStrength(event.target.value, defaultOptions).value);

        if (
          passwordStrength(event.target.value, defaultOptions).value ===
          'Too weak'
        ) {
          setCss('text-danger');
        } else if (
          passwordStrength(event.target.value, defaultOptions).value === 'Weak'
        ) {
          setCss('text-danger');
        } else if (
          passwordStrength(event.target.value, defaultOptions).value ===
          'Medium'
        ) {
          setCss('text-warning');
        } else if (
          passwordStrength(event.target.value, defaultOptions).value ===
          'Strong'
        ) {
          setCss('text-success');
        }
      }

      switch (event.target.name) {
        case 'email':
          setEmail(event.target.value);
          break;
        case 'password':
          setPassword(event.target.value);
          break;
        case 'first_name':
          setFirstName(event.target.value);
          break;
        case 'last_name':
          setLastName(event.target.value);
          break;
        case 'phone_number':
          setPhoneNumber(event.target.value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    Axios.post(`${Home}enter-ps/${type ? 'login' : 'register'}`, {
      type: 2,
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      account_type: 'enterprise',
      otp: 'none',
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        const { data } = res;
        if (res.data.success) {
          localStorage.setItem('userToken', JSON.stringify(data.payload.token));

          history.push('/dashboard');
        } else {
          ErrorHandler(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const { data } = err.response;
          ErrorHandler(data.message);
        } else {
          ErrorHandler(err.message);
        }
      });
  };

  useEffect(() => {
    dispatch(login_layout('STEPHEN_WHOCODED'));

    return () => {
      dispatch(login_layout(''));
    };
  }, []);

  const Switch = (name) => {
    setSwitchName(name);
  };

  return (
    <>
      {switchName === 'WHOCODED' ? (
        <ForgottenPass close={() => Switch('')} />
      ) : (
        ''
      )}

      <div className=''>
        <Toaster />

        <div className=''>
          <div className='content-l d-flex st-h-100 justify-content-center align-items-center bgg'>
            <form onSubmit={handleSubmit} className='login-form'>
              <div className='card mb-0'>
                {type ? (
                  <div className='card-body'>
                    <div className='text-center mb-3'>
                      <div className='d-inline-flex align-items-center justify-content-center mb-4 mt-2'>
                        <img className='img-fluid h-48px' src={img} />
                      </div>
                      <h5 className='text-primary2 mg-b-5 text-center'>
                        Login to your account
                      </h5>
                      <p className='text-primary22 mg-b-40 text-muted'>
                        Enter your credentials below
                      </p>
                    </div>

                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Email address
                      </label>
                      <input
                        onChange={handleChange}
                        name='email'
                        value={email}
                        type='email'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                    </div>
                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Password
                      </label>
                      <input
                        name='password'
                        value={password}
                        onChange={handleChange}
                        type='password'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                      <small style={{ fontSize: '14px' }} className={`${css}`}>
                        {strength}
                      </small>
                    </div>
                    <div
                      className='text-primary22 tx-13 mg-t0 mb-3 tx-center text-right'
                      style={{ fontWeight: '900' }}
                    >
                      <Link
                        onClick={() => Switch('WHOCODED')}
                        className='text-primary3'
                        to='#'
                      >
                        Forgotten password?
                      </Link>
                    </div>
                    <div className='form-group'>
                      {loading ? (
                        <Spinner size={25} />
                      ) : password.length > 0 && email.length > 0 ? (
                        <button
                          type='submit'
                          className='btn btn-primary st-btn shadow btn-block btn-sm'
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          type='button'
                          disabled={false}
                          className='btn btn-primary st-btn btn-sm shadow btn-block'
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='card-body'>
                    <div className='text-center mb-3'>
                      <div className='d-inline-flex align-items-center justify-content-center mb-4 mt-2'>
                        <img className='img-fluid h-48px' src={img} />
                      </div>
                      <h5 className='text-primary2 mg-b-5 text-center'>
                        Create an account
                      </h5>
                      <p className='text-primary22 mg-b-40 text-muted'>
                        Enter your details below to register
                      </p>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label className='text-primary22 st-label'>
                            First name
                          </label>
                          <input
                            onChange={handleChange}
                            name='first_name'
                            value={firstName}
                            type='text'
                            required
                            className='form-control st-login-f'
                            id='inlineFormInputGroup'
                            placeholder=''
                          />
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label className='text-primary22 st-label'>
                            Last name
                          </label>
                          <input
                            onChange={handleChange}
                            name='last_name'
                            value={lastName}
                            type='text'
                            required
                            className='form-control st-login-f'
                            id='inlineFormInputGroup'
                            placeholder=''
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Phone Number
                      </label>
                      <input
                        onChange={handleChange}
                        name='phone_number'
                        value={phoneNumber}
                        type='number'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder='+2347060622780'
                      />
                    </div>

                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Email address
                      </label>
                      <input
                        onChange={handleChange}
                        name='email'
                        value={email}
                        type='email'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                    </div>
                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Password
                      </label>
                      <input
                        name='password'
                        value={password}
                        onChange={handleChange}
                        type='password'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                      <small style={{ fontSize: '14px' }} className={`${css}`}>
                        {strength}
                      </small>
                    </div>

                    <div className='form-group'>
                      <label className='text-primary22 st-label'>
                        Confirm Password
                      </label>
                      <input
                        name='password2'
                        value={password}
                        onChange={handleChange}
                        type='password'
                        required
                        className='form-control st-login-f'
                        id='inlineFormInputGroup'
                        placeholder=''
                      />
                    </div>

                    <div className='form-group'>
                      {loading ? (
                        <Spinner size={25} />
                      ) : password.length > 0 && email.length > 0 ? (
                        <button
                          type='submit'
                          className='btn btn-primary st-btn shadow btn-block btn-sm'
                        >
                          Create account
                        </button>
                      ) : (
                        <button
                          type='button'
                          disabled={false}
                          className='btn btn-primary st-btn btn-sm shadow btn-block'
                        >
                          Create account
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {type ? (
                <div
                  className=' tx-13 mg-t-20 tx-center'
                  style={{ fontWeight: '900', color: '#d1cccc' }}
                >
                  Don't have an account?{' '}
                  <Link
                    className='text-light'
                    onClick={() => setType(!type)}
                    to='#'
                  >
                    Create an account
                  </Link>
                </div>
              ) : (
                <div
                  className=' tx-13 mg-t-20 tx-center'
                  style={{ fontWeight: '900', color: '#d1cccc' }}
                >
                  Already have an account?{' '}
                  <Link
                    className='text-light'
                    onClick={() => setType(!type)}
                    to='#'
                  >
                    Login to your account
                  </Link>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Login);
