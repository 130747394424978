import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';

// let data = [
//   {
//     id: 'Users',
//     color: 'hsl(309, 70%, 50%)',
//     data: [

//       {
//         x: 'Users',
//         y: 18,
//       },

//     ],
//   },
//   {
//     id: 'Vehicles',
//     color: 'hsl(260, 70%, 50%)',
//     data: [
//       {
//         x: 'Vehicles',
//         y: 125,
//       },
//     ],
//   },
//   {
//     id: 'Claims',
//     color: 'hsl(23, 70%, 50%)',
//     data: [
//       {
//         x: 'Claims',
//         y: 125,
//       }
//     ],
//   },
//   {
//     id: 'Policies',
//     color: 'hsl(1, 70%, 50%)',
//     data: [
//       {
//         x: 'Policies',
//         y: 140,
//       },

//     ],
//   },
// ];

class LineChart extends Component {
  render() {
    const { LineData } = this.props;
    return (
      <div style={{ height: '400px', width: '700px' }}>
        <ResponsiveLine
          data={LineData}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=' >-.2f'
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

export default LineChart;
