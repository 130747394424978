import React, { Component } from 'react';
import { connect } from 'react-redux';
import { open_menu } from '../store/actions/OpenMenu';
import { open_main_menu } from '../store/actions/MainMenu';
import { change_breadcrum } from '../store/actions/Bredcrum';
import CompanyContainer from '../components/company/CompanyContainer';
class CompanyPage extends Component {
  componentDidMount() {
    this.props.dispatch(open_menu('company'));
    this.props.dispatch(open_main_menu('company'));
    this.props.dispatch(change_breadcrum('My Company'));
  }
  render() {
    return (
      <div>
        <div className='mb-4'>
          <center>{/* <img src={data} className="img-fluid" /> */}</center>
        </div>
        <CompanyContainer />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(CompanyPage);
