import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home, formatAmount } from '../../../global/Home';
import Spinner from '../../../global/Spinner';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from 'react-feather';

let token = '';
const MarineInsurance = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [negotiated_rate, setNegotiatedRate] = useState('');
  const [exchange_rate, setExchangeRate] = useState('');
  const [invoice_value, setInvoiceValue] = useState('');
  const [co_insurance, setCoInsurance] = useState('');
  const [excess, setExcess] = useState('');
  const [lien_clause, setLienClause] = useState('');
  const [vessel_name, setVesselName] = useState('');

  const [CurrencyCodeData, setCurrencyCodeData] = useState([]);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState([]);
  const [selectedCurrencyCodeData, setSelectedCurrencyCodeData] =
    useState(null);

  const [PolicyTypeData, setPolicyTypeData] = useState([]);
  const [selectedPolicyType, setSelectedPolicyType] = useState([]);
  const [selectedPolicyTypeData, setSelectedPolicyTypeData] = useState(null);

  const [MarinePerData, setMarinePerData] = useState([]);
  const [selectedMarinePer, setSelectedMarinePer] = useState([]);
  const [selectedMarinePerData, setSelectedMarinePerData] = useState(null);

  const [PackingTypeData, setPackingTypeData] = useState([]);
  const [selectedPackingType, setSelectedPackingType] = useState([]);
  const [selectedPackingTypeData, setSelectedPackingTypeData] = useState(null);

  const [MarinePortOriginData, setMarinePortOriginData] = useState([]);
  const [selectedMarinePortOrigin, setSelectedMarinePortOrigin] = useState([]);
  const [selectedMarinePortOriginData, setSelectedMarinePortOriginData] =
    useState(null);

  const [MarinePortDestinationData, setMarinePortDestinationData] = useState(
    []
  );
  const [selectedMarinePortDestination, setSelectedMarinePortDestination] =
    useState([]);
  const [
    selectedMarinePortDestinationData,
    setSelectedMarinePortDestinationData,
  ] = useState(null);

  const [MarineClauseData, setMarineClauseData] = useState([]);
  const [selectedMarineClause, setSelectedMarineClause] = useState([]);
  const [selectedMarineClauseData, setSelectedMarineClauseData] =
    useState(null);

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  //Load data for marine currency code datas
  const LoadCurrencyCodeData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=CURRENCY_CODE&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setCurrencyCodeData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedCurrencyCode && CurrencyCodeData) {
      const currencyCode = CurrencyCodeData.find(
        (item) => item.id === selectedCurrencyCode
      );
      setSelectedCurrencyCodeData(currencyCode);
    } else {
      setSelectedCurrencyCodeData(null);
    }
  }, [selectedCurrencyCode, CurrencyCodeData]);

  const CurrencyCodeOptions = CurrencyCodeData
    ? CurrencyCodeData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine policy type code datas
  const LoadPolicyTypeData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=INSURANCE_POLICY&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setPolicyTypeData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedPolicyType && PolicyTypeData) {
      const policyType = PolicyTypeData.find(
        (type) => type.id === selectedCurrencyCode
      );
      setSelectedPolicyTypeData(policyType);
    } else {
      setSelectedPolicyTypeData(null);
    }
  }, [selectedPolicyType, PolicyTypeData]);

  const policyTypeOptions = PolicyTypeData
    ? PolicyTypeData.map((type) => ({
        id: type.id,
        value: type.id,
        label: type.name,
        description: type.description,
        name: type.name,
        code: type.code,
        type: type.type,
      }))
    : [];

  //Load data for marine policy type code datas
  const LoadMarinePerData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=PER&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setMarinePerData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedMarinePer && MarinePerData) {
      const marinePer = MarinePerData.find(
        (item) => item.id === selectedMarinePer
      );
      setSelectedMarinePerData(marinePer);
    } else {
      setSelectedMarinePerData(null);
    }
  }, [selectedMarinePer, MarinePerData]);

  const marinePerOptions = MarinePerData
    ? MarinePerData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine policy type code datas
  const LoadPackingTypeData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=PACKING_TYPE&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setPackingTypeData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedPackingType && PackingTypeData) {
      const packingType = PackingTypeData.find(
        (item) => item.id === selectedPackingType
      );
      setSelectedPackingTypeData(packingType);
    } else {
      setSelectedPackingTypeData(null);
    }
  }, [selectedPackingType, PackingTypeData]);

  const packingTypeOptions = PackingTypeData
    ? PackingTypeData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine port origin type code datas
  const LoadMarinePortOriginData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(
        `${Home}variations?type=EXTERNAL_MARINE_PORT&vendor=NOOR_TAKAFUL`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          setMarinePortOriginData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedMarinePortOrigin && MarinePortOriginData) {
      const marinePortOrigin = MarinePerData.find(
        (item) => item.id === selectedMarinePortOrigin
      );
      setSelectedMarinePortOriginData(marinePortOrigin);
    } else {
      setSelectedMarinePortOriginData(null);
    }
  }, [selectedMarinePortOrigin, MarinePortOriginData]);

  const marinePortOriginOptions = MarinePortOriginData
    ? MarinePortOriginData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine port origin type code datas
  const LoadMarinePortDestinationData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=MARINE_PORT&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setMarinePortDestinationData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedMarinePortDestination && MarinePortDestinationData) {
      const marinePortDestination = MarinePortDestinationData.find(
        (item) => item.id === selectedMarinePortDestination
      );
      setSelectedMarinePortDestinationData(marinePortDestination);
    } else {
      setSelectedMarinePortDestinationData(null);
    }
  }, [selectedMarinePortDestination, MarinePortDestinationData]);

  const marinePortDestinationOptions = MarinePortDestinationData
    ? MarinePortDestinationData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine clause code datas
  const LoadMarineClauseData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=MARINE_CLAUSE&vendor=NOOR_TAKAFUL`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setMarineClauseData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedMarineClause && MarineClauseData) {
      const marineClause = MarineClauseData.find(
        (item) => item.id === selectedMarineClause
      );
      setSelectedMarineClauseData(marineClause);
    } else {
      setSelectedMarineClauseData(null);
    }
  }, [selectedMarineClause, MarineClauseData]);

  const marineClauseOptions = MarineClauseData
    ? MarineClauseData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  useEffect(() => {
    LoadCurrencyCodeData();
    LoadPolicyTypeData();
    LoadMarinePerData();
    LoadMarinePortOriginData();
    LoadMarinePortDestinationData();
    LoadMarineClauseData();
    LoadPackingTypeData();
  }, []);

  const handleBuyMarineInsurance = (e) => {
    e.preventDefault();

    const generateRandomString = (length) => {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const randomTransactionID = generateRandomString(12);

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.post(
        `${Home}noor-takaful/buy-policy/marine`,
        {
          transaction_id: randomTransactionID,
          email: email,
          invoice_value: invoice_value,
          negotiated_rate: negotiated_rate,
          transaction_currency_code: selectedCurrencyCode.code,
          exchange_rate: exchange_rate,
          marine_policy_type_code: selectedPolicyType.code,
          per_code: selectedMarinePer.code,
          packing_type_code: selectedPackingType.code,
          co_insurance: co_insurance,
          excess: excess,
          lien_clause: lien_clause,
          marine_port_origin_external_code: selectedMarinePortOrigin.code,
          marine_port_destination_code: selectedMarinePortDestination.code,
          vessel_name: vessel_name,
          clause_code: selectedMarineClause.code,
          insurance_type: 'marine',
          insurance_code: 'marine',
          insurance_plan_code: 'noor-takaful-marine',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          toast.success('Marine Policy Purchased Successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });

          setLoading(false);
          history.push('/policies');
        })
        .catch((err) => {
          console.log(err);
          const { data } = err.response;
          toast.error(data?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setLoading(false);
        });
    }
  };

  const handleFormDisable = () => {
    if (
      !email ||
      !negotiated_rate ||
      !exchange_rate ||
      !invoice_value ||
      !co_insurance ||
      !excess ||
      !lien_clause ||
      !vessel_name ||
      !selectedCurrencyCode.id ||
      !selectedPolicyType.id ||
      !selectedMarinePer.id ||
      !selectedMarinePortOrigin.id ||
      !selectedMarinePortDestination.id ||
      !selectedMarineClause.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div class='container-sm'>
      <div class='pb-4'>
        <button onClick={handleGoBack} className='btn btn-primary '>
          {' '}
          <ArrowLeft /> Back
        </button>
      </div>

      <div class='row'>
        <form
          onSubmit={handleBuyMarineInsurance}
          className='card container w-100 p-5 m-auto flex flex-column'
        >
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Email Address</label>
            <input
              className='form-control'
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email Address'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Negotiation Rate</label>
            <input
              className='form-control'
              type='text'
              value={negotiated_rate}
              onChange={(e) => setNegotiatedRate(e.target.value)}
              placeholder='Set Negotiated Rate'
            />
          </div>
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Exchange Rate</label>
            <input
              className='form-control'
              type='text'
              value={exchange_rate}
              onChange={(e) => setExchangeRate(e.target.value)}
              placeholder='Set Exchange Rate'
            />
          </div>
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Invoice Value</label>
            <input
              className='form-control'
              type='text'
              value={invoice_value}
              onChange={(e) => setInvoiceValue(e.target.value)}
              placeholder='Set Invoice Value'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='marine-policy-type'>
              Marine Policy Type
            </label>
            <Select
              isSearchable
              id='marine-policy-type'
              className='w-100 z-index-1'
              value={selectedPolicyType}
              onChange={(selectedOption) =>
                setSelectedPolicyType(selectedOption)
              }
              options={policyTypeOptions}
              placeholder='Select Marine Policy Type'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='transaction-currency'>
              Transaction Currency
            </label>
            <Select
              isSearchable
              id='transaction-currency'
              className='w-100 z-index-1'
              value={selectedCurrencyCode}
              onChange={(selectedOption) =>
                setSelectedCurrencyCode(selectedOption)
              }
              options={CurrencyCodeOptions}
              placeholder='Select Transaction Currency'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='marine-per'>
              Marine Per
            </label>
            <Select
              isSearchable
              id='marine-per'
              className='w-100 z-index-1'
              value={selectedMarinePer}
              onChange={(selectedOption) =>
                setSelectedMarinePer(selectedOption)
              }
              options={marinePerOptions}
              placeholder='Select Marine Per'
            />
          </div>
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='packing-type'>
              Packing Type
            </label>
            <Select
              isSearchable
              id='packing-type'
              className='w-100 z-index-1'
              value={selectedPackingType}
              onChange={(selectedOption) =>
                setSelectedPackingType(selectedOption)
              }
              options={packingTypeOptions}
              placeholder='Select Marine Per'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='marine-clause'>
              Marine Clause
            </label>
            <Select
              isSearchable
              id='marine-clause'
              className='w-100 z-index-1'
              value={selectedMarineClause}
              onChange={(selectedOption) =>
                setSelectedMarineClause(selectedOption)
              }
              options={marineClauseOptions}
              placeholder='Select Marine Clause'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Co-Insurance</label>
            <input
              className='form-control'
              type='text'
              value={co_insurance}
              onChange={(e) => setCoInsurance(e.target.value)}
              placeholder='Set Co-Insurance'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Excess</label>
            <input
              className='form-control'
              type='text'
              value={excess}
              onChange={(e) => setExcess(e.target.value)}
              placeholder='Set Excess'
            />
          </div>
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Lien Clause</label>
            <input
              className='form-control'
              type='text'
              value={lien_clause}
              onChange={(e) => setLienClause(e.target.value)}
              placeholder='Set Lien Clause'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label'>Vessel Name</label>
            <input
              className='form-control'
              type='text'
              value={vessel_name}
              onChange={(e) => setVesselName(e.target.value)}
              placeholder='Set Vessel Name'
            />
          </div>

          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='marine-port-external-origin'>
              Marine External Port Origin
            </label>
            <Select
              isSearchable
              id='marine-port-external-origin'
              className='w-100 z-index-1'
              value={selectedMarinePortOrigin}
              onChange={(selectedOption) =>
                setSelectedMarinePortOrigin(selectedOption)
              }
              options={marinePortOriginOptions}
              placeholder='Select Marine External Port Origin'
            />
          </div>
          <div className='form-group flex-column flex mb-2'>
            <label className='form-label' htmlFor='marine-port-destination'>
              Marine Port Destination
            </label>
            <Select
              isSearchable
              id='marine-port-destination'
              className='w-100 z-index-1'
              value={selectedMarinePortDestination}
              onChange={(selectedOption) =>
                setSelectedMarinePortDestination(selectedOption)
              }
              options={marinePortDestinationOptions}
              placeholder='Select Marine Port Destination'
            />
          </div>

          <button
            type='submit'
            disabled={handleFormDisable()}
            class='btn btn-primary'
          >
            {loading ? 'Loading...' : 'Purchase'}
          </button>
          {loading && <Spinner />}
        </form>
      </div>
    </div>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(MarineInsurance);
