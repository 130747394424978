import React, { Component } from 'react';
import { FadeIn } from 'animate-components';
import { connect } from 'react-redux';
import { switch_content } from '../../store/actions/SwitchContent';
import { props_params } from '../../store/actions/PropsParams';
import { PlusCircle } from 'react-feather';
import Axios from 'axios';
import { Home } from '../../global/Home';
import Spinner from '../../global/Spinner';
import img from '../../assets/svg/company.svg';
import { open_right } from '../../store/actions/OpenRight';
import moment from 'moment';
import { toast } from 'react-toastify';

class ListRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: '',
      modal: '',
      show: '',
      id: '',
      switch: '',
      filter: 'all',
      detail: {},
    };
  }

  LoadClaims = (filter) => {
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      this.setState({ loading: true });

      Axios.get(`${Home}claims/read/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          this.setState({ loading: false, data: res.data.payload });
          if (res.data && res.data.payload) {
            res.data.payload.forEach((payload) => {
              const { id } = payload;
              if (id) {
                this.setState({ id: id });
                console.log('this is the id:', id);
              } else {
                console.error('Id is not Found in payload');
              }
            });
          }
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  approveClaim = (idToUpdate) => {
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      const updatedStatus = 'approved'; // Set the updated status value

      // Make a PUT request to update the status
      Axios.put(
        `${Home}claims/request/admin/response`,
        { id: idToUpdate, status: updatedStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log('Status updated successfully:', res.data);

          // Update the responseData state to reflect the new status
          const updatedData = this.state.data.map((user) => {
            if (user.id === idToUpdate) {
              return { ...user, status: updatedStatus };
            }
            return user;
          });
          this.setState({ data: updatedData });

          toast.success('Status updated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });

          // window.location.reload();
        })

        .catch((err) => console.log('Error updating claim status:', err));
    }
  };

  updateStatusForUser = (id) => {
    this.approveClaim(id);
  };

  Filter = (filter) => {
    this.LoadClaims(filter);
    this.setState({ filter: filter });
  };

  componentDidMount() {
    this.LoadClaims(this.state.filter);
  }

  handleChange = (event) => {
    if (event.target.type !== 'files') {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === 'type') {
        this.setState({ switch: event.target.value });
      }
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentWillUnmount() {
    this.props.dispatch(open_right('Open'));
  }

  render() {
    return (
      <>
        <FadeIn duration='1s' timingFunction='ease-out'>
          <div className=' border-0'>
            <div className='row g-0'>
              <div className='col-md-12'>
                {this.state.loading ? (
                  <div className='p-5'>
                    <Spinner size={70} />
                  </div>
                ) : this.state.data.length < 1 ? (
                  <div className='p-5'>
                    <center>
                      <img
                        style={{ width: '30%' }}
                        className='img-fluid w-50'
                        src={img}
                      />
                    </center>
                    <div className='pt-4 alert-secondry text-center'>
                      No Claims setup yet.
                    </div>
                  </div>
                ) : (
                  <div className='row mb-4'>
                    {this.state.data.map((claim, i) => (
                      <div key={i} className='col-md-4 mb-3'>
                        <FadeIn duration='1s' timingFunction='ease-out'>
                          <div className='card card-profile st-member'>
                            <div className=' '>
                              <center>
                                <div className=''>
                                  <video width='200' height='200' controls>
                                    <source
                                      src={claim?.details?.video}
                                      type='video/mp4'
                                    />
                                  </video>
                                </div>
                              </center>
                            </div>
                            <div className='table-responsive'>
                              <table
                                style={{ minHeight: '291px' }}
                                className='table table-sm table-primary mb-0 -word'
                              >
                                <tr>
                                  <td className='word1'>Fullname </td>
                                  <td>{`${claim?.user?.first_name} ${claim?.user?.last_name}`}</td>
                                </tr>
                                <tr>
                                  <td className='word1'>Claim Type </td>
                                  <td className='word2'>
                                    {claim?.details?.type}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email:</td>
                                  <td> {claim?.user?.email} </td>
                                </tr>

                                <tr>
                                  <td>Phone:</td>
                                  <td> {claim?.user?.phone_number} </td>
                                </tr>

                                <tr>
                                  <td>Claim cause:</td>
                                  <td>{`${claim?.details?.cause}`}</td>
                                </tr>

                                <tr>
                                  <td>Claim Latitude:</td>
                                  <td>{`${claim?.details?.latitude}`}</td>
                                </tr>
                                <tr>
                                  <td>Claim Longitude:</td>
                                  <td>{`${claim?.details?.longitude}`}</td>
                                </tr>
                                <tr>
                                  <td>Claim Status:</td>
                                  <td>
                                    {' '}
                                    {claim?.status == 'pending' ? (
                                      <span className='badge badge-pill badge-warning'>
                                        {claim?.status}
                                      </span>
                                    ) : (
                                      <span className='badge badge-pill badge-success'>
                                        {claim?.status}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date:</td>
                                  <td>
                                    {moment(claim?.created_at).format(
                                      'MMM Do, YYYY'
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {claim?.status === 'approved'
                                      ? 'Claim Approved'
                                      : 'Approve Claim'}
                                  </td>
                                  <td style={{}}>
                                    <button
                                      type='button'
                                      className={`p-2 rounded ${
                                        claim?.status === 'approved'
                                          ? 'btn btn-secondary'
                                          : 'btn btn-primary'
                                      }`}
                                      onClick={() =>
                                        this.updateStatusForUser(claim?.id)
                                      }
                                      disabled={claim?.status === 'approved'}
                                    >
                                      {claim?.status === 'approved'
                                        ? 'Claim Approved'
                                        : 'Approve Claim'}
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </FadeIn>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListRequests);
