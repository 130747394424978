import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change_breadcrum } from '../../../store/actions/Bredcrum';
import Marineinsurance from '../../../components/AgInsurance/marine/MarineInsurance';

const Marine = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum('AG Marine Insurance'));
  }, []);
  return <Marineinsurance />;
};

export default Marine;
