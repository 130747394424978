import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { change_breadcrum } from '../../store/actions/Bredcrum';
import HallmarkComponent from '../../components/Hallmark';

const HallmarkPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum('Hallmark Insurance'));
  }, []);

  return <HallmarkComponent />;
};

export default HallmarkPage;
