import React, { Component } from 'react';
import { FadeIn } from 'animate-components';
import { connect } from 'react-redux';
import { switch_content } from '../../store/actions/SwitchContent';
import { props_params } from '../../store/actions/PropsParams';

import { PlusCircle } from 'react-feather';
import Axios from 'axios';
import { Home } from '../../global/Home';
import Spinner from '../../global/Spinner';
import img from '../../assets/svg/company.svg';
import { open_right } from '../../store/actions/OpenRight';
import img2 from '../../assets/img/haba.jpeg';
import moment from 'moment';

class ListCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: {},
      name: '',
      modal: '',
      show: '',
      id: 0,
      switch: '',
      filter: 'all',
      detail: {},
    };
  }

  LoadData = (filter) => {
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      this.setState({ loading: true });

      Axios.get(`${Home}enter-ps/user/getCompany`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          this.setState({ loading: false, data: res.data.payload });
          console.log(res);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };

  Filter = (filter) => {
    this.LoadData(filter);
    this.setState({ filter: filter });
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
  }

  handleChange = (event) => {
    if (event.target.type !== 'files') {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === 'type') {
        this.setState({ switch: event.target.value });
      }
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentWillUnmount() {
    this.props.dispatch(open_right('Open'));
  }

  render() {
    return (
      <>
        <div className='row'>
          <div className='col-md-12' style={{ paddingBottom: '20px' }}>
            <div className='pull-right'>
              <FadeIn duration='1s' timingFunction='ease-out'>
                {(!this.data || Object.keys(this.data).length === 0) && (
                  <button
                    onClick={() => this.SwitchContent('add', 0)}
                    className='btn btn-primary shadow'
                  >
                    <PlusCircle color='white' size={35} /> Add Company
                  </button>
                )}
              </FadeIn>
            </div>
          </div>
        </div>

        <FadeIn duration='1s' timingFunction='ease-out'>
          <div className=' border-0'>
            <div className='row g-0'>
              <div className='col-md-12'>
                {this.state.loading ? (
                  <div className='p-5'>
                    <Spinner size={70} />
                  </div>
                ) : !this.state.data ||
                  Object.keys(this.state.data).length === 0 ? (
                  <div className='p-5'>
                    <center>
                      <img
                        style={{ width: '30%' }}
                        className='img-fluid w-50'
                        src={img}
                      />
                    </center>
                    <div className='pt-4 alert-secondry text-center'>
                      No company setup yet. Please add your company details
                    </div>
                  </div>
                ) : (
                  <div className='row mb-4'>
                    <div className='col-md-12 mb-3'>
                      <FadeIn duration='1s' timingFunction='ease-out'>
                        <div className='card card-profile st-member'>
                          <div className='card-body tx-13'>
                            <center>
                              <div className='st-img-co'>
                                <img
                                  className='img-fluid'
                                  style={{
                                    width: 'auto',
                                    height: '200px',
                                  }}
                                  src={img}
                                  alt=''
                                />
                              </div>
                            </center>
                          </div>
                          <div className='table-responsive'>
                            <table
                              style={{ minHeight: '291px', padding: '20px' }}
                              className='table table-sm table-primary mb-0 -word'
                            >
                              <tr>
                                <td className='word1'>Company Name </td>
                                <td className='word2'>
                                  {this.state.data?.name}
                                </td>
                              </tr>
                              <tr>
                                <td>Company Address</td>
                                <td> {this.state.data?.address} </td>
                              </tr>

                              <tr>
                                <td>Email:</td>
                                <td>{`${this.state.data?.email}`}</td>
                              </tr>

                              <tr>
                                <td>Phone:</td>
                                <td>{`${this.state.data?.phone_number}`}</td>
                              </tr>
                              <tr>
                                <td style={{ borderBottomLeftRadius: '13px' }}>
                                  Date:
                                </td>
                                <td
                                  style={{
                                    borderBottomRightRadius: '13px',
                                  }}
                                >
                                  {moment(this.state.data.updated_at).format(
                                    'MMM Do, YYYY'
                                  )}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </FadeIn>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListCompanies);
