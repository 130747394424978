import React, { Component } from 'react';
import { BounceRight, FadeIn } from 'animate-components';
import { connect } from 'react-redux';
import { switch_content } from '../../store/actions/SwitchContent';
import { props_params } from '../../store/actions/PropsParams';
import { PlusCircle, ArrowLeft, Trash } from 'react-feather';
import { toast } from 'react-toastify';
import { launch_toaster } from '../../store/actions/IsToast';
import { toast_trigger } from '../../store/actions/ToastTrigger';
import { Home } from '../../global/Home';
import Axios from 'axios';
import Spinner from '../../global/Spinner';

import 'react-datepicker/dist/react-datepicker.css';

class ApproveRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: '',
      name: '',
      loading: false,
      data: [],
      details: [],
      lg: [],
      lgs: '',
      state: '',
      country: '',
      address: '',
      show: '',
      note: '',
      number: '',
      email: '',
      phone_number: '',
    };
    this.fileInput = React.createRef();
  }

  handleChange = (event) => {
    if (event.target.type !== 'files') {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === 'state') {
        if (this.state.details[event.target.value].lgas) {
          this.setState({ lg: this.state.details[event.target.value].lgas });
        }
      }
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      this.setState({ loading: true });
      Axios.post(
        `${Home}enter-ps/user/createCompany`,
        {
          name: this.state.name,
          address: this.state.address,
          phone_number: this.state.phone_number,
          email: this.state.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.SwitchContent('', [0]);
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response) {
            const { data } = err.response;
            this.props.dispatch(launch_toaster(data.errors));
            this.props.dispatch(toast_trigger(false));
          } else {
            this.props.dispatch(launch_toaster(err.message));
            this.props.dispatch(toast_trigger(false));
          }
        });
    }
  };

  render() {
    return (
      <>
        <FadeIn duration='1s' timingFunction='ease-out'>
          <div className='card border-0'>
            <div className='card-body'>
              <div className='row pb-2'>
                <div className='col-md-5'>
                  <h6 class='lh-5 mg-b-0'>Add Company</h6>
                </div>

                <div className='col-md-7'>
                  <div className='pull-right'>
                    <FadeIn duration='1s' timingFunction='ease-out'>
                      <button
                        onClick={() => this.SwitchContent('list', [0])}
                        className='btn btn-danger shadow'
                      >
                        <ArrowLeft color='white' size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              <form onSubmit={this.handleSubmit} className='mt-4 row'>
                <div className='col-md-12'>
                  <div className='form-group mt-5'>
                    <label>Name</label>
                    <input
                      value={this.state.name}
                      name='name'
                      required
                      placeholder='Name'
                      className='form-control st-login-f22'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='form-group '>
                    <label>Addess</label>
                    <input
                      value={this.state.address}
                      name='address'
                      placeholder='Address'
                      className='form-control st-login-f22'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='form-group '>
                    <label>Phone Number</label>
                    <input
                      value={this.state.phone_number}
                      name='phone_number'
                      placeholder='Phone Numer'
                      className='form-control st-login-f22'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='form-group '>
                    <label>Company Email </label>
                    <input
                      value={this.state.email}
                      name='email'
                      placeholder='Email '
                      className='form-control st-login-f22'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className='col-md-12' style={{ marginTop: '3rem' }}>
                  <div className='form-group'>
                    {this.state.loading ? (
                      <Spinner size={40} />
                    ) : (
                      <button className='btn st-btn2 btn-primary shadow'>
                        Save Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ApproveRequest);
