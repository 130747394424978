import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home, formatAmount } from '../../../global/Home';
import Spinner from '../../../global/Spinner';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ArrowLeft } from 'react-feather';

let token = '';
const Travelinsurance = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [CountriesData, setCountriesData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryData, setSelectedCountryData] = useState(null);

  const [packageData, setPackageData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageData, setSelectedPackageData] = useState(null);

  const [departure_date, setDepartureDate] = useState('');
  const [return_date, setReturnDate] = useState('');

  const [numAccompanyingUsers, setNumAccompanyingUsers] = useState(0);
  const [accompanyingUsers, setAccompanyingUsers] = useState([]);

  const [quoteData, setQuoteData] = useState(null);

  const handleNumAccompanyingUsersChange = (e) => {
    let value = parseInt(e.target.value);
    // Ensure the value is between 0 and 5
    value = isNaN(value) ? 0 : Math.min(Math.max(0, value), 5);
    setNumAccompanyingUsers(value);
  };

  // Update accompanying user data when a field is modified
  const handleAccompanyingUserChange = (index, field, value) => {
    // If an accompanying user is being modified, update the corresponding state
    const newAccompanyingUsers = [...accompanyingUsers];
    newAccompanyingUsers[index] = {
      ...newAccompanyingUsers[index],
      [field]: value,
    };
    setAccompanyingUsers(newAccompanyingUsers);
  };

  //handle the correct date format
  const formattedDepartureDate = moment(departure_date).format('DD-MMM-YYYY');
  const handleDepartureDate = (event) => {
    const inputDate = event.target.value;
    setDepartureDate(inputDate);
  };

  const formattedReturnDate = moment(return_date).format('DD-MMM-YYYY');
  const handleReturnDate = (event) => {
    const inputDate = event.target.value;
    setReturnDate(inputDate);
  };

  // console.log('this is the Future date:', formattedDepartureDate);

  //Load data of users
  const LoadUserData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.get(`${Home}enter-ps/user/listUsers/Employees/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setUserData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  //Load Countries
  const LoadCountries = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.get(`${Home}enter-ps/travels/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setCountriesData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  //Load Package
  const LoadPackages = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.get(`${Home}enter-ps/travels/plan-packages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setPackageData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    LoadUserData();
    LoadCountries();
    LoadPackages();
  }, []);

  // Update selected user data when the selected user changes
  useEffect(() => {
    if (selectedUser && UserData) {
      const user = UserData.find((user) => user.id === selectedUser);
      setSelectedUserData(user);
    } else {
      setSelectedUserData(null);
    }
  }, [selectedUser, UserData]);

  //set the options
  //check if userdata exists before rendering
  const userOptions = UserData
    ? UserData.map((user) => ({
        label: `${user.first_name} ${user.last_name} - ${user.email} -  ${user._id}`,
        value: user._id,
        user_id: user._id,
        user_email: user.email,
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name,
      }))
    : [];

  // Update selected country data when the selected country changes
  useEffect(() => {
    if (selectedCountry && CountriesData) {
      const country = CountriesData.find(
        (country) => country.id === selectedCountry
      );
      setSelectedCountryData(country);
    } else {
      setSelectedCountryData(null);
    }
  }, [selectedCountry, CountriesData]);

  //set the options
  //check if countrydata exists before rendering
  const countryOptions = CountriesData
    ? CountriesData.map((country) => ({
        label: `${country.name} - ${country.code}`,
        value: country.id,
        name: country.name,
        code: country.code,
      }))
    : [];

  // Update selected package data when the selected package changes
  useEffect(() => {
    if (selectedPackage && packageData) {
      const packageItem = packageData.find(
        (packageItem) => packageItem.id === selectedPackage
      );
      setSelectedPackageData(packageItem);
    } else {
      setSelectedPackageData(null);
    }
  }, [selectedPackage, packageData]);

  //set the options
  //check if packagedata exists before rendering
  const packageOptions = packageData
    ? packageData.map((packageItem) => ({
        label: `${packageItem.name} - ${packageItem.code}`,
        value: packageItem.id,
        name: packageItem.name,
        code: packageItem.code,
      }))
    : [];

  //Get Quote before payment
  const getQuote = () => {
    const dobList = accompanyingUsers.map((user) => ({
      dob: moment(user.dob, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
    }));
    const quoteRequestData = {
      insurance_plan_code: selectedPackage.code,
      departure_date: formattedDepartureDate,
      return_date: formattedReturnDate,
      dob_list: dobList,
    };

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.post(`${Home}enter-ps/travels/price`, quoteRequestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const receivedQuoteData = res.data.payload;
          setQuoteData(receivedQuoteData);

          setLoading(false);
          // console.log(setQuoteData);
          toast.success('Travel Insurance Quote Generated Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          const { data } = err.response;
          toast.error(data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    }
  };

  //Handle the Buy Travel Insurance
  const handleBuyTravelPolicy = (e) => {
    e.preventDefault();

    // Check if a user is selected
    if (!selectedUser.user_id) {
      toast.error('Please select a user before purchasing car insurance', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      return;
    }

    // If there are accompanying users, add them to the travelers array
    const travelers = [
      ...accompanyingUsers.map((user) => ({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        passport_number: user.passport_number || '',
        gender: user.gender || '',
        dob: moment(user.dob, 'YYYY-MM-DD').format('DD-MMM-YYYY') || '',
      })),
    ];

    const generateRandomString = (length) => {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const randomTransactionID = generateRandomString(12);

    // console.log(randomTransactionID); // Output a random alphanumeric string of length 12

    const travelersDatas = {
      amount: parseFloat(quoteData?.TotalAmount.replace(/,/g, '')),
      transaction_id: randomTransactionID,
      insurance_plan_code: selectedPackage.code,
      insurance_type: 'travel',
      user_id: selectedUser.user_id,
      email: selectedUser.user_email,
      phone_number: selectedUser.phone_number,
      departure_date: formattedDepartureDate,
      return_date: formattedReturnDate,
      country_code: selectedCountry.code,
      travelers: travelers,
    };

    // console.log(`this is the travelersData: ${JSON.stringify(travelersDatas)}`);

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.post(`${Home}enter-ps/travels/buy`, travelersDatas, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          toast.success('Buy Travel Policy successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setLoading(false);
          console.log(res);

          // resetForm();
          history.push('/policies');
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div class='pb-4'>
        <button onClick={handleGoBack} className='btn btn-primary '>
          {' '}
          <ArrowLeft /> Back
        </button>
      </div>
      <form
        onSubmit={handleBuyTravelPolicy}
        className='card container w-100 p-5 m-auto flex flex-column'
      >
        <div className='form-group flex-column flex mb-2'>
          <label className='form-label' htmlFor='user-select'>
            Select User
          </label>
          <Select
            isSearchable
            id='user-select'
            className='w-100 z-index-1'
            value={selectedUser}
            onChange={(selectedOption) => setSelectedUser(selectedOption)}
            options={userOptions}
            placeholder='Select User'
          />
        </div>

        <div className='form-group flex-column flex mb-2'>
          <label className='form-label' htmlFor='country-select'>
            Select Country
          </label>
          <Select
            isSearchable
            id='country-select'
            className='w-100 z-index-1'
            value={selectedCountry}
            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
            options={countryOptions}
            placeholder='Select Country'
          />
          <small>Country destination of traveler</small>
        </div>

        <div className='form-group flex-column flex mb-2'>
          <label className='form-label' htmlFor='package-select'>
            Select Package
          </label>
          <Select
            isSearchable
            id='package-select'
            className='w-100 z-index-1'
            value={selectedPackage}
            onChange={(selectedOption) => setSelectedPackage(selectedOption)}
            options={packageOptions}
            placeholder='Select Package'
          />
        </div>

        <div className='form-group flex-column flex mb-2'>
          <label className='form-label' htmlFor='departureDate'>
            Departure Date
          </label>
          <input
            className='form-control'
            id='departureDate'
            type='date'
            value={departure_date}
            onChange={handleDepartureDate}
            placeholder='dd-MMM-yyyy (e.g., 02-Mar-2024)'
          />
        </div>

        <div className='form-group flex-column flex mb-2'>
          <label className='form-label' htmlFor='returnDate'>
            Return Date
          </label>
          <input
            className='form-control'
            id='returnDate'
            type='date'
            value={return_date}
            onChange={handleReturnDate}
            placeholder='dd-MMM-yyyy (e.g., 02-Mar-2024)'
          />
        </div>

        <div className='form-group flex-column flex mb-6'>
          <label className='form-label' htmlFor='numAccompanyingUsers'>
            Number of Accompanying Users
          </label>
          <select
            className='form-control w-50 mb-4 z-index-0'
            value={numAccompanyingUsers}
            onChange={handleNumAccompanyingUsersChange}
          >
            <option value=''>Select No of Accompanies </option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
          </select>

          <small>
            <i>input no of users that will accompany you, first user is you.</i>
          </small>
        </div>

        {Array.from({ length: numAccompanyingUsers }, (_, index) => (
          <div key={index} className='mt-4'>
            <h2>Accompanying User {index + 1}</h2>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor={`first_name_${index}`}>
                First Name
              </label>
              <input
                className='form-control'
                type='text'
                id={`first_name_${index}`}
                value={accompanyingUsers[index]?.first_name || ''}
                onChange={(e) =>
                  handleAccompanyingUserChange(
                    index,
                    'first_name',
                    e.target.value
                  )
                }
                placeholder='First Name'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor={`last_name_${index}`}>
                Last Name
              </label>
              <input
                className='form-control'
                type='text'
                id={`last_name_${index}`}
                value={accompanyingUsers[index]?.last_name || ''}
                onChange={(e) =>
                  handleAccompanyingUserChange(
                    index,
                    'last_name',
                    e.target.value
                  )
                }
                placeholder='Last Name'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label
                className='form-label'
                htmlFor={`passport_number_${index}`}
              >
                Passport Number
              </label>
              <input
                className='form-control'
                type='text'
                id={`passport_number_${index}`}
                value={accompanyingUsers[index]?.passport_number || ''}
                onChange={(e) =>
                  handleAccompanyingUserChange(
                    index,
                    'passport_number',
                    e.target.value
                  )
                }
                placeholder='Passport Number'
              />
              <small>Passport must be 9 characters long</small>
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor={`gender_${index}`}>
                Gender
              </label>
              <input
                className='form-control'
                type='text'
                id={`gender_${index}`}
                value={accompanyingUsers[index]?.gender || ''}
                onChange={(e) =>
                  handleAccompanyingUserChange(index, 'gender', e.target.value)
                }
                placeholder='Gender'
              />
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label' htmlFor={`dob_${index}`}>
                DOB
              </label>
              <input
                className='form-control'
                type='date'
                id={`dob_${index}`}
                value={accompanyingUsers[index]?.dob || ''}
                onChange={(e) =>
                  handleAccompanyingUserChange(index, 'dob', e.target.value)
                }
                placeholder='DOB (e.g., 01-Jan-1992)'
              />
            </div>
          </div>
        ))}

        <button
          className='btn st-btn2 btn-primary shadow mt-5'
          type='button'
          onClick={getQuote}
        >
          {loading ? 'Loading...' : 'Get Quote'}
        </button>

        {quoteData && (
          <div>
            <h1 className='py-4 text-center font-weight-bold'>
              Quote for TravelInsurance
            </h1>
            <table className='table table-sm table-primary table-bordered table-striped'>
              <thead className='text-dark font-weight-bold'>
                <tr>
                  <th className='font-weight-bold'>Total Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='font-weight-bold'>{quoteData?.TotalAmount}</td>
                </tr>
              </tbody>
            </table>
            <button className='btn st-btn2 btn-primary shadow' type=''>
              Pay Now
            </button>
            {loading && <Spinner />}
          </div>
        )}
      </form>
    </>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Travelinsurance);
