import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home, formatAmount } from '../../../global/Home';
import Spinner from '../../../global/Spinner';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from 'react-feather';

let token = '';
const MarineInsurance = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [steps, setStep] = useState(0);

  const [invoice_amount, setInvoiceAmount] = useState('');
  const [currency_naira_exchange_rate, setExchangeRate] = useState('');
  const [tax_id, setTaxId] = useState('');
  const [invoice_date, setInvoiceDate] = useState('');
  const [invoice_number, setInvoiceNumber] = useState('');
  const [company_type, setCompanyType] = useState('');
  const [marinePriceData, setMarinePriceData] = useState(null);
  const [companyInsuredData, setCompanyInsuredData] = useState(null);

  const [UserData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [ShippingItemData, SetShippingItemData] = useState([]);
  const [selectedShippingItem, setSelectedShippingItem] = useState([]);
  const [selectedShippingItemData, setSelectedShippingItemData] =
    useState(null);

  const [MarineClauseData, setMarineClauseData] = useState([]);
  const [selectedMarineClause, setSelectedMarineClause] = useState([]);
  const [selectedMarineClauseData, setSelectedMarineClauseData] =
    useState(null);

  const [CurrencyCodeData, setCurrencyCodeData] = useState([]);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState([]);
  const [selectedCurrencyCodeData, setSelectedCurrencyCodeData] =
    useState(null);

  const [StateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedStateData, setSelectedStateData] = useState(null);

  const [CountriesData, setCountriesData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryData, setSelectedCountryData] = useState(null);

  const [PortData, setPortData] = useState([]);
  const [selectedPort, setSelectedPort] = useState('');
  const [selectedPortData, setSelectedPortData] = useState(null);

  const [marine_policy_type, setMarinePolicyType] = useState('');
  const [cargo_package_type, setCargoPackageType] = useState('');
  const [goods_class, setGoodsClass] = useState('');
  const [goods_condition, setGoodsCondition] = useState('');
  const [goods_description, setGoodsDesc] = useState('');
  const [per_vessel_type, setVesselType] = useState('');
  const [nature_of_cargo, setNatureOfCargo] = useState('');

  //handle form steps
  const handleFormStepsNext = () => {
    setStep(steps + 1);
  };
  const handleFormStepsPrev = () => {
    setStep(steps - 1);
  };

  //Load data of users
  const LoadUserData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}enter-ps/user/listUsers/Employees/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setUserData(res.data.payload);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedUser && UserData) {
      const user = UserData.find((user) => user.id === selectedUser);
      setSelectedUserData(user);
    } else {
      setSelectedUserData(null);
    }
  }, [selectedUser, UserData]);

  //set the options
  //check if userdata exists before rendering
  const userOptions = UserData
    ? UserData.map((user) => ({
        label: `${user.first_name} ${user.last_name} - ${user.email} -  ${user._id}`,
        value: user._id,
        user_id: user._id,
        user_email: user.email,
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name,
      }))
    : [];

  // console.log('this is the marine price data', marinePriceData);

  useEffect(() => {
    LoadUserData();
    LoadShippingItemData();
    LoadMarineClauseData();
    LoadCurrencyCodeData();
    LoadStates();
    LoadCountries();
    LoadPort();
  }, []);

  //Load data of States
  const LoadStates = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}states`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setStateData(res.data.payload);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected State data when the select changes
  useEffect(() => {
    if (selectedState && StateData) {
      const states = StateData.find((state) => state.id === selectedState);
      setSelectedStateData(states);
    } else {
      setSelectedStateData(null);
    }
  }, [selectedState, StateData]);

  //set the options
  //check if userdata exists before rendering
  const StateOptions = StateData
    ? StateData.map((state) => ({
        label: state.name,
        value: state.id,
        id: state.id,
        name: state.name,
        ag_identifier: state.ag_identifier,
      }))
    : [];

  //Load data for shipping items code datas
  const LoadShippingItemData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=MARINE_SHIPPING_ITEM_CODE&vendor=AG`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          SetShippingItemData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedShippingItem && ShippingItemData) {
      const shippingItem = ShippingItemData.find(
        (item) => item.id === selectedShippingItem
      );
      setSelectedShippingItemData(shippingItem);
    } else {
      setSelectedShippingItemData(null);
    }
  }, [selectedShippingItem, ShippingItemData]);

  const shippingItemOptions = ShippingItemData
    ? ShippingItemData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine clause code datas
  const LoadMarineClauseData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=MARINE_CLAUSE&vendor=AG`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setMarineClauseData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedMarineClause && MarineClauseData) {
      const marineClause = MarineClauseData.find(
        (item) => item.id === selectedMarineClause
      );
      setSelectedMarineClauseData(marineClause);
    } else {
      setSelectedMarineClauseData(null);
    }
  }, [selectedMarineClause, MarineClauseData]);

  const marineClauseOptions = MarineClauseData
    ? MarineClauseData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load data for marine currency code datas
  const LoadCurrencyCodeData = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      // setLoading(true);
      Axios.get(`${Home}variations?type=CURRENCY_CODE&vendor=AG`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setCurrencyCodeData(res.data.data);
          // setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (selectedCurrencyCode && CurrencyCodeData) {
      const currencyCode = CurrencyCodeData.find(
        (item) => item.id === selectedCurrencyCode
      );
      setSelectedCurrencyCodeData(currencyCode);
    } else {
      setSelectedCurrencyCodeData(null);
    }
  }, [selectedCurrencyCode, CurrencyCodeData]);

  const CurrencyCodeOptions = CurrencyCodeData
    ? CurrencyCodeData.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        description: item.description,
        name: item.name,
        code: item.code,
        type: item.type,
      }))
    : [];

  //Load Countries
  const LoadCountries = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));

      Axios.get(`${Home}travels/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setCountriesData(res.data.payload);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected country data when the selected country changes
  useEffect(() => {
    if (selectedCountry && CountriesData) {
      const country = CountriesData.find(
        (country) => country.id === selectedCountry
      );
      setSelectedCountryData(country);
    } else {
      setSelectedCountryData(null);
    }
  }, [selectedCountry, CountriesData]);

  //set the options
  //check if countrydata exists before rendering
  const countryOptions = CountriesData
    ? CountriesData.map((country) => ({
        label: `${country.name} - ${country.code}`,
        value: country.id,
        name: country.name,
        code: country.code,
      }))
    : [];

  //Load Countries
  const LoadPort = () => {
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));

      Axios.get(`${Home}variations?type=MARINE_PORT&vendor=AG`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res);
          setPortData(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  // Update selected country data when the selected country changes
  useEffect(() => {
    if (selectedPort && PortData) {
      const port = PortData.find((port) => port.id === selectedPort);
      setSelectedPortData(port);
    } else {
      setSelectedPortData(null);
    }
  }, [selectedPort, PortData]);

  //set the options
  //check if countrydata exists before rendering
  const portOptions = PortData
    ? PortData.map((port) => ({
        label: `${port.name} - ${port.code}`,
        value: port.id,
        name: port.name,
        code: port.code,
      }))
    : [];

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if a user is selected
    if (!selectedUser.user_id) {
      toast.error('Please select a user before purchasing Marine Insurance', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      return;
    }

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);

      Axios.post(
        `${Home}marine/price`,
        {
          invoice_amount: invoice_amount,
          shipping_item_code: selectedShippingItem.code,
          marine_clause_code: selectedMarineClause.code,
          transaction_currency_code: selectedCurrencyCode.code,
          currency_naira_exchange_rate: currency_naira_exchange_rate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          toast.success('Price Insured Generated', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setMarinePriceData(res.data.payload);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleCompanySubmit = (e) => {
    e.preventDefault();

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.post(
        `${Home}marine/insure-company`,
        {
          email: selectedUser.user_email,
          phone_number: selectedUser.phone_number,
          tax_id: tax_id,
          company_name: `${selectedUser.first_name} ${selectedUser.last_name}`,
          company_type: company_type,
          ag_state_identifier: selectedState.ag_identifier,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          toast.success('Company Insured Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          setCompanyInsuredData(res.data.payload);
          setLoading(false);
          handleFormStepsNext();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleBuyMarineInsurance = (e) => {
    e.preventDefault();

    const generateRandomString = (length) => {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const randomTransactionID = generateRandomString(12);

    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      Axios.post(
        `${Home}marine/buy`,
        {
          invoice_amount: invoice_amount,
          amount: marinePriceData.amount,
          transaction_id: randomTransactionID,
          insurance_type: 'marine',
          insurance_plan_code: 'default-marine',
          company_id: companyInsuredData.CompanyID,
          marine_policy_type: marine_policy_type,
          invoice_number: invoice_number,
          invoice_date: invoice_date,
          cargo_package_type: cargo_package_type,
          goods_class: goods_class,
          goods_condition: goods_condition,
          goods_description: goods_description,
          departure_country_code: selectedCountry.code,
          depature_port: selectedPort.name,
          destination_port_code: selectedPort.code,
          per_vessel_type: per_vessel_type,
          shipping_item_code: selectedShippingItem.code,
          marine_clause_code: selectedMarineClause.code,
          transaction_currency_code: selectedCurrencyCode.code,
          nature_of_cargo: nature_of_cargo,
          currency_naira_exchange_rate: currency_naira_exchange_rate,
          email: selectedUser.user_email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          toast.success('Marine Policy Purchased Successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });

          setLoading(false);
          history.push('/policies');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleFormOneDisable = () => {
    if (
      !selectedUser.user_id ||
      !selectedCurrencyCode.id ||
      !selectedMarineClause.id ||
      !invoice_amount ||
      !selectedShippingItem.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFormTwoDisable = () => {
    if (
      !selectedUser.first_name ||
      !selectedUser.user_email ||
      !tax_id ||
      !company_type ||
      !selectedState.id
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div class='container-sm'>
      <div class='pb-4'>
        <button onClick={handleGoBack} className='btn btn-primary '>
          {' '}
          <ArrowLeft /> Back
        </button>
      </div>

      <div class='row'>
        {steps === 0 && (
          <form
            onSubmit={handleSubmit}
            className='card container w-100 p-5 m-auto flex flex-column'
          >
            <h1>Step 1 - Get Insured Price </h1>
            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='user-select'>
                Select User
              </label>
              <Select
                isSearchable
                id='user-select'
                className='w-100 z-index-1'
                value={selectedUser}
                onChange={(selectedOption) => setSelectedUser(selectedOption)}
                options={userOptions}
                placeholder='Select User'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Invoice Amount</label>
              <input
                className='form-control'
                type='text'
                value={invoice_amount}
                onChange={(e) => setInvoiceAmount(e.target.value)}
                placeholder='Invoice Amount'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Currency Exchange Rate</label>
              <input
                className='form-control'
                type='text'
                value={currency_naira_exchange_rate}
                onChange={(e) => setExchangeRate(e.target.value)}
                placeholder='Currency Exchange Rate'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='shipping-item'>
                Shipping Item
              </label>
              <Select
                isSearchable
                id='shipping-item'
                className='w-100 z-index-1'
                value={selectedShippingItem}
                onChange={(selectedOption) =>
                  setSelectedShippingItem(selectedOption)
                }
                options={shippingItemOptions}
                placeholder='Select Shipping Item'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='marine-clause'>
                Marine Clause
              </label>
              <Select
                isSearchable
                id='marine-clause'
                className='w-100 z-index-1'
                value={selectedMarineClause}
                onChange={(selectedOption) =>
                  setSelectedMarineClause(selectedOption)
                }
                options={marineClauseOptions}
                placeholder='Select Marine Clause'
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='currency-code'>
                Currency Code
              </label>
              <Select
                isSearchable
                id='currency-code'
                className='w-100 z-index-1'
                value={selectedCurrencyCode}
                onChange={(selectedOption) =>
                  setSelectedCurrencyCode(selectedOption)
                }
                options={CurrencyCodeOptions}
                placeholder='Select Currency Code'
              />
            </div>

            <button
              type='submit'
              disabled={handleFormOneDisable()}
              class='btn btn-primary'
            >
              {loading ? 'Loading...' : 'Submit'}
            </button>
            {loading && <Spinner />}

            {marinePriceData && (
              <div>
                <h1 className='py-4 text-center font-weight-bold'>
                  Price Insured for Marine Insurance
                </h1>
                <table className='table table-sm table-primary table-bordered table-striped'>
                  <thead className='text-dark font-weight-bold'>
                    <tr>
                      <th className='font-weight-bold'>Total Amount</th>
                      <th className='font-weight-bold'>Sum Insured</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>
                        {formatAmount(marinePriceData?.amount)}
                      </td>
                      <td className='font-weight-bold'>
                        {formatAmount(marinePriceData?.sum_insured)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className='btn st-btn2 btn-primary shadow'
                  type=''
                  onClick={handleFormStepsNext}
                >
                  Next
                </button>
              </div>
            )}
          </form>
        )}

        {steps === 1 && (
          <form
            onSubmit={handleCompanySubmit}
            className='card container w-100 p-5 m-auto flex flex-column'
          >
            <h1>Step 2 - Company Insured</h1>
            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Company Name </label>
              <input
                className='form-control'
                type='text'
                value={`${selectedUser.first_name} ${selectedUser.last_name}`}
                readOnly
              />
            </div>
            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Phone Number </label>
              <input
                className='form-control'
                type='text'
                value={selectedUser.phone_number}
                readOnly
              />
            </div>
            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Email </label>
              <input
                className='form-control'
                type='email'
                value={selectedUser.user_email}
                readOnly
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Tax ID </label>
              <input
                className='form-control'
                type='text'
                value={tax_id}
                onChange={(e) => setTaxId(e.target.value)}
              />
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Company Type</label>
              <select
                className='form-control w-75 mb-4'
                value={company_type}
                onChange={(e) => setCompanyType(e.target.value)}
              >
                <option value=''>Select Company Type </option>
                <option value='individual'>Individual</option>
                <option value='corporate'>Corporate</option>
                <option value='government'>Government</option>
              </select>
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='state'>
                State
              </label>
              <Select
                isSearchable
                id='currency-code'
                className='w-100 z-index-1'
                value={selectedState}
                onChange={(selectedOption) => setSelectedState(selectedOption)}
                options={StateOptions}
                placeholder='Select State '
              />
            </div>

            <div class='d-flex '>
              <button
                type='button'
                onClick={handleFormStepsPrev}
                class='btn btn-primary mr-2'
              >
                Prev
              </button>

              <button disabled={handleFormTwoDisable()} class='btn btn-primary'>
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
            {loading && <Spinner />}
          </form>
        )}

        {steps === 2 && (
          <form
            onSubmit={handleBuyMarineInsurance}
            className='card container w-100 p-5 m-auto flex flex-column'
          >
            <h1>Step 3 - Purchase Marine Insurance</h1>
            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Marine Policy Type</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={marine_policy_type}
                onChange={(e) => setMarinePolicyType(e.target.value)}
              >
                <option value=''>Select Marine Policy Type </option>
                <option value='single_transit_policy'>
                  Single Transit Policy
                </option>
                <option value='open_cover_policy'>Open Cover Policy</option>
              </select>
            </div>

            <div className='form-group flex-column flex '>
              <label className='form-label' htmlFor='Invoice Date'>
                Invoice Date
              </label>
              <input
                type='date'
                className='form-control w-100'
                placeholder='Invoice Date'
                value={invoice_date}
                onChange={(e) => setInvoiceDate(e.target.value)}
              />
            </div>
            <div className='form-group flex-column flex '>
              <label className='form-label' htmlFor='Invoice Date'>
                Invoice Number
              </label>
              <input
                type='text'
                className='form-control w-100'
                placeholder='Invoice Number'
                value={invoice_number}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Cargo Package Type</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={cargo_package_type}
                onChange={(e) => setCargoPackageType(e.target.value)}
              >
                <option value=''>Select Cargo Package Type </option>
                <option value='containerized'>Containerized</option>
                <option value='non_containerized'>Non-Containerized</option>
              </select>
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Goods Class</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={goods_class}
                onChange={(e) => setGoodsClass(e.target.value)}
              >
                <option value=''>Select Goods Class </option>
                <option value='cargo'>Cargo</option>
                <option value='non_cargo'>Non-Cargo</option>
              </select>
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Goods Condition</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={goods_condition}
                onChange={(e) => setGoodsCondition(e.target.value)}
              >
                <option value=''>Select Goods Class </option>
                <option value='good'>Good</option>
                <option value='breakable'>Breakable</option>
                <option value='perishable'>Perishable</option>
              </select>
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Vessel Type</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={per_vessel_type}
                onChange={(e) => setVesselType(e.target.value)}
              >
                <option value=''>Select Vessel Type </option>
                <option value='sea_travelling_vessel'>
                  Sea Travelling Vessel
                </option>
                <option value='air_travelling_vessel'>
                  Air Travelling Vessel
                </option>
              </select>
            </div>

            <div className='form-group flex-column flex mb-5'>
              <label className='form-label'>Nature of Cargo</label>
              <select
                className='form-control w-75 mb-4 z-index-0'
                value={nature_of_cargo}
                onChange={(e) => setNatureOfCargo(e.target.value)}
              >
                <option value=''>Select Nature of Cargo </option>
                <option value='machinery'>Machinery</option>
                <option value='general_merchandize'>General Merchandize</option>
              </select>
            </div>

            <div className='form-group flex-column flex '>
              <label className='form-label'>Goods Description</label>
              <textarea
                type='text'
                className='form-control w-100'
                placeholder='Write down the goods description....'
                value={goods_description}
                col={10}
                row={10}
                onChange={(e) => setGoodsDesc(e.target.value)}
              ></textarea>
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='country'>
                Departure Country
              </label>
              <Select
                isSearchable
                id='currency-code'
                className='w-100 z-index-1'
                value={selectedCountry}
                onChange={(selectedOption) =>
                  setSelectedCountry(selectedOption)
                }
                options={countryOptions}
                placeholder='Select Departure Country '
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label' htmlFor='country'>
                Departure Port
              </label>
              <Select
                isSearchable
                id='currency-code'
                className='w-100 z-index-1'
                value={selectedPort}
                onChange={(selectedOption) => setSelectedPort(selectedOption)}
                options={portOptions}
                placeholder='Select Departure Port '
              />
            </div>

            <div className='form-group flex-column flex mb-2'>
              <label className='form-label'>Amount Payable </label>
              <input
                className='form-control'
                type='text'
                value={formatAmount(marinePriceData.amount)}
                readOnly
              />
            </div>

            <div class='d-flex '>
              <button
                type='button'
                onClick={handleFormStepsPrev}
                class='btn btn-primary mr-2'
              >
                Prev
              </button>

              <button class='btn btn-primary'>
                {loading ? 'Loading...' : 'Purchase'}
              </button>
            </div>
            {loading && <Spinner />}
          </form>
        )}
      </div>
    </div>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(MarineInsurance);
