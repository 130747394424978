import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change_breadcrum } from '../../../store/actions/Bredcrum';
import MarineInsurance from '../../../components/NoorTakaful/marine/MarineInsurance';

const Marine = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change_breadcrum('Noor Takaful Marine Insurance'));
  }, []);

  return <MarineInsurance />;
};

export default Marine;
