import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddCompany from './AddCompany';
import ListCompanies from './ListCompanies';

class CompanyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      current: 'List',
    };
  }

  Switcher = () => {
    let current = this.props.data.switch;
    switch (current) {
      case 'list':
        return <ListCompanies />;
      case 'add':
        return <AddCompany />;

      default:
        return <ListCompanies />;
    }
  };

  render() {
    return <div>{this.Switcher()}</div>;
  }
}

const mapStateToProps = (store) => ({
  data: store,
});

export default connect(mapStateToProps)(CompanyContainer);
